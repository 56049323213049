import { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";

import React from "react";

export const Contact = (props) => {
  const [selectedComptype, setSelectedComptype] = useState("1");
  const [selectedCompcat, setSelectedCompcat] = useState("1");
  const [selectedEmsschk, setSelectedEmsschk] = useState('1');
  const [selectedIcligmchk, setSelectedIcligmchk] = useState('1');
  const [selectedOtherchk, setSelectedOtherchk] = useState('1');
  const [selectedChatbotchk, setSelectedChatbotchk] = useState('1');
  const [compcatOptions, setCompcatOptions] = useState(['1']);
  const [selectedSoftradio, setSelectedSoftradio] = useState('');
  const [error, setError] = useState({ softradio: '', compname: '' });
  const [success, setSuccess] = useState(false); //form validation
  const [gosuccess, setGosuccess] = useState(false);
  const [vosuccess, setVosuccess] = useState(false);
  const [vmosuccess, setVmosuccess] = useState(false);
  const [subsuccess, setSubsuccess] = useState(false);
  const [vduplicate, setVduplicate] = useState(false);
  const [insucess, setInsucess] = useState(false);
  const [vmotp, setVmotp] = useState(false);
  const [veotp, setVeotp] = useState(false);  
  const [submf, setSubmf] = useState(false);    
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [content, setContent] = useState('Welcome to ICL');
  const [compcatval, setCompcatval] = useState("Please select Software first to proceed");
  const [softSelect, setSoftSelect] = useState(false);  
//  const [wrongEaddFlag, setWrongEaddFlag] = useState(false);  
  const [contnm, setContnm] = useState('India');
  const [reotp, setReotp] = useState('');  
  let isValid = true;
  //camel cases
// Function to initialize Google Analytics
const initGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
};
function gtag() {
  window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'AW-1070405040');

 const [formData, setFormData] = useState({
    compname: '',
    compcity: '',
    compstat: '',
    firstname: '',
    lastname: '',
    desig: '',
    softradio: '',
//    reotp:'',
    website: ''
    // Add more fields as needed
  });
//pin code
const [numData, setNumData] = useState({
    comppin: ''
    // Add more fields as needed
  });
//Tele number
const [telData, setTelData] = useState({
    teld: ''
    // Add more fields as needed
  });
  //Mobile number
const [mobileData, setMobileData] = useState({
    mobile: '',
    motp: ''
    // Add more fields as needed
  });
//Email
const [emailData, setEmailData] = useState({
    email: '',
    eotp: ''
    // Add more fields as needed
  });

  const [compkey, setCompkey] = useState(0);//compregkeyid after submit


  //Email ID
const emailid = (input) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};  
  //Mobile number
const mobilenos = (input) => {
  return input
.replace(/[^\d]/, '') // Preserve numbers
};  
  //Tele number
const telnos = (input) => {
  return input
.replace(/[^\d,]/g, '') // Preserve numbers and comma
};  
  //pin code
const pincodes = (input) => {
  return input
.replace(/[^\d\s]/g, '') // Preserve numbers and white spaces
};  

//Email ID
const handleEmailInput = (event) => {
  const { name, value } = event.target;
  const emailValue = emailid(value);
  setEmailData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
  setEmailStatus(emailValue);
};
const [emailValue, setEmailStatus] = useState(true);
//Mobile numbers
const handleMobileInput = (event) => {
  const { name, value } = event.target;
  // Convert input value to tele numbers
  const mobileValue = mobilenos(value);
  setMobileData((prevData) => ({
    ...prevData,
    [name]: mobileValue,
  }));
};
//tele numbers
const handleTelInput = (event) => {
  const { name, value } = event.target;
  // Convert input value to tele numbers
  const telValue = telnos(value);
  setTelData((prevData) => ({
    ...prevData,
    [name]: telValue,
  }));
};
//pin codes
const handlePinInput = (event) => {
  const { name, value } = event.target;
  // Convert input value to pin code
  const numValue = pincodes(value);
  setNumData((prevData) => ({
    ...prevData,
    [name]: numValue,
  }));
};
//camel cases
const camelCaseWithSpaces = (input) => {
  return input
    .replace(/[^\w\s]/g, '')  // Preserve alphanumeric characters and white spaces
    .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1));
};
//camel case
const handleInputChange = (event) => {
  const { name, value } = event.target;
  // Convert input value to camel case
  const camelCaseValue = camelCaseWithSpaces(value);
  setFormData((prevData) => ({
    ...prevData,
    [name]: camelCaseValue,
  }));
};
const handleWebsiteChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};
  //Radio buttons Software select
  const handleEmsschk = (event) => {
  console.log("emsschk");
    setSelectedEmsschk('EMSS Software');
    setCompcatOptions(['1','2', '3', '4']);
    setCompcatval("Select Business category");
    setSelectedCompcat('1');
    setSelectedSoftradio(event.target.value);
    setSoftSelect(true);
    setFormData((prevData) => ({
      ...prevData,
      softradio: 'emssenq',
    }));
  };
  const handleIcligmchk = (event) => {
   console.log("icligmchk");
  setSelectedIcligmchk('ICLIGM Software');
  setCompcatOptions(['1','5', '6', '7', '8']);
  setCompcatval("Select Business category");
  setSelectedCompcat('1');
  setSelectedSoftradio(event.target.value);
  setSoftSelect(true);
  setFormData((prevData) => ({
    ...prevData,
    softradio: 'icligmenq',
  }));
};
  const handleOtherchk = (event) => {
    console.log("otherchk");
   setSelectedOtherchk('Others');
   setCompcatOptions(['1','2','3','4','5', '6', '7', '8', '9']);
   setCompcatval("Select Business category");
   setSelectedCompcat('1');
   setSelectedSoftradio(event.target.value);
   setSoftSelect(true);
   setFormData((prevData) => ({
    ...prevData,
    softradio: 'others',
  }));
  };
  const handleChatbotchk = (event) => {
    console.log("chatbotchk");
   setSelectedChatbotchk('chatbot');
   setCompcatOptions(['1','9','10','11','12']);
   setCompcatval("Select Business category");
   setSelectedCompcat('1');
   setSelectedSoftradio(event.target.value);
   setSoftSelect(true);
   setFormData((prevData) => ({
    ...prevData,
    softradio: 'chatbot',
  }));
  };
 
      // Helper function to get the corresponding label for each option
const getOptionLabel = (option) => {
  switch (option) {
    case '1':
      return compcatval;      
      // return 'Select Business category';
    case '2':
      return 'Importer (Merc/Mfg)';
    case '3':
      return 'Exporter (Merc/Mfg)';
    case '4':
      return 'Import-Export(Both)';
    case '5':
      return 'Clearing and Forwarding';
    case '6':
      return 'Shipping Agency';
    case '7':
      return 'Shipping Line';
    case '8':
      return 'Container Line';
    case '9':
        return 'IT Company';      
    case '10':
         return 'Importer-Exporter';      
    case '11':
          return 'Shipping Company';      
    case '12':
          return 'Other';      
            
        default:
      return '1';
  }
};
//Company type selection
  const handleComptypeChange = (event) => {
    const selectedValue = event.target.value;
    // Check if the selected value is not the default one
    if (selectedValue !== "1") {
      setSelectedComptype(selectedValue);
    }
  }; 
  //Business category
  const handleCompcatChange = (event) => {
    const selectedValue = event.target.value;
    // Check if the selected value is not the default one
    if (selectedValue !== "1") {
      setSelectedCompcat(selectedValue);
    }
  };

  //form validations  
  const handleSubmit = (event) => {
//    console.log("Form validations started!");
    event.preventDefault();
     isValid = true;
//     setWrongEaddFlag(false);//270125

    // Check if software is selected
    if (!formData.softradio) {
      setError((prev) => ({ ...prev, softradio: 'Please select a software.'+error }));
      setContent("Please select a software.");
      isValid = false;
    }

    // Check if company name is provided
    if (!formData.compname) {
      setError((prev) => ({ ...prev, compname: 'Please enter company name.' }));
      setContent("Please enter company name.");
      isValid = false;
    }
 // Check if company type is selected
 if (selectedComptype === "1") {
  setError((prev) => ({ ...prev, comptype: 'Please select a valid company type.' }));
  setContent("Please select a valid company type.");
  isValid = false;
}
 // Check if business category is selected
 if (selectedCompcat === "1") {
  setError((prev) => ({ ...prev, comptype: 'Please select a valid business category.' }));
  setContent("Please select a valid business category.");
  isValid = false;
}
    if (isValid) {
      console.log("Form validations validated!");
      setError({ softradio: '', compname: '' });
      setSuccess(true);
      console.log("Form validations success");
      setContent("Form validations successfull. You may generate OTPs now!!!");
    }
  };
  //Generate OTP
  const handleGotp = (event) => {
    event.preventDefault();
  //  let isValid = true;
  
    if (isValid) {
      console.log("Duplicate check-true");
      setVduplicate(true);
      setLoading(true);
      setError({ motp: '', eotp: '' });
    }
  };

  const gtag_report_conversion=useCallback((url)=> {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
  if (formData.softradio==='emssenq') {
    console.log("gtag-emss");
    gtag('event', 'conversion', {
      'send_to': 'AW-1070405040/U6NpCP7QydsBELCrtP4D',
      'value': 1.0,
      'currency': 'INR',
      'event_callback': callback
  });
    // gtag('event', 'conversion', {'send_to': 'AW-1070405040/U6NpCP7QydsBELCrtP4D'});
}
if (formData.softradio==='icligmenq') {
    console.log("gtag-icligm");
    gtag('event', 'conversion', {
      'send_to': 'AW-1070405040/kdQ9CISF1tsBELCrtP4D',
      'value': 1.0,
      'currency': 'INR',
      'event_callback': callback
  });
    // gtag('event', 'conversion', {'send_to': 'AW-1070405040/kdQ9CISF1tsBELCrtP4D'});     
}
if (formData.softradio==='chatbot') {
  console.log("gtag-chatbot");
  gtag('event', 'conversion', {
    'send_to': 'AW-1070405040/ruXJCMfbnr8ZELCrtP4D',
    'value': 1.0,
    'currency': 'INR',
    'event_callback': callback
}); 
}
return false;
},[formData.softradio]);

//260424 await duplicate
//Await 240624 Gen OTPs API
//&timestamp=${encodeURIComponent(new Date().getTime())}
useEffect(() => {
  const fetchData = async () => {
    try {
      console.log("Aproaching Duplicate API ...");      
//270125      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=contchkf&timestamp=${encodeURIComponent(new Date().getTime())}&email=${encodeURIComponent(emailData.email)}&mobile=${encodeURIComponent(mobileData.mobile)}`);
      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=contchkf&email=${encodeURIComponent(emailData.email)}&mobile=${encodeURIComponent(mobileData.mobile)}`);

      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      const responseData = await response.text();
      // Process the responseData as needed
      console.log('Data received:', responseData);
      console.log("Duplicate response received !!!");
      const lines = responseData.split('\n');
      let msg='';
      for (let line of lines) { //Data
        if (line.trim().startsWith("mcontchkf")) { 
          msg = line.trim().substring(9);
          console.log("Duplicate status:"+msg);
        }
        }      
      if(msg==="success") {
        setContent("No duplicate. Generating OTPs ...");
        console.log("OTP Gen activated!");
        setVduplicate(false);
        setSubsuccess(true); //ready to submit
        setLoading(false);
      }
      else {
        setContent("Duplicate entry !!!");      
        setSuccess(false);
      }
      
    } catch (error) {
      console.error('Duplicate API error:', error.message);
      setContent("Duplicate API Generated !!!");
      setLoading(false);
    }
  }; 
   if(emailData.email!=='' && mobileData.mobile!=='' && vduplicate && !subsuccess) {
    if(loading) {
      setContent("Click Verify to proceed ....")
     }  
    console.log("Aproaching Duplicates API"+emailData.email+" "+mobileData.mobile);
    fetchData(); 
  }
}, [vduplicate,emailData.email,mobileData.mobile,loading,subsuccess]);
//check reotp
useEffect(() => {
  console.log("Updated reotp+insuccess+vmotp+Veotp: " + reotp+insucess+vmotp+veotp); // This will log the updated value
}, [reotp,insucess,vmotp,veotp]); // This effect runs whenever reotp changes

//Await 240624 Gen OTPs API
//timestamp="+new Date().getTime()+"&
useEffect(() => {
  const fetchData = async () => {
    try {
      console.log("Aproaching Gen OTPs API ...");      
      let decl;
      if(`${contnm}`.toLowerCase().includes('India'.toLowerCase())) {
        decl = "https://servguest.eximon.com/ServConI?page=eotpf&email=" + encodeURIComponent(emailData.email) + "&mobile=" + encodeURIComponent(mobileData.mobile);
        console.log("Aproaching API for India eOTP");
      }
      else {
        decl = "https://servguest.eximon.com/ServConI?page=eotp-only&email=" + encodeURIComponent(emailData.email);
        console.log("Aproaching API for overseas eOTP");
      }
      const response = await fetch(decl,{cache: 'no-store'});
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      const responseData = await response.text();
      // Process the responseData as needed
      console.log('Data received:', responseData);
      
      const lines = responseData.split('\n');
      let emsg='';
      let mmsg='';
      for (let line of lines) { //Data
        if (line.trim().startsWith("meotpf")) { //email OTP 
          emsg = line.trim().substring(6);
          if(emsg==="success") {
           if(!gosuccess) {
            setGosuccess(true);
           }
            console.log("eOTP message:"+emsg);
          }
            else {
              setContent("Email OTP Error !");            
            }
        }
        if (line.trim().startsWith("reotp")) { //random email otp from API
          console.log("e OTP Line entered");
          let eo = line.trim().substring(5);
           console.log("API eotp:");
           setReotp(eo);
          console.log("reOTP :");
        }
        if (line.trim().startsWith("mmotpf") && `${contnm}`.toLowerCase().includes('India'.toLowerCase())) { //mobile OTP
          console.log("Delivering mobile otp message");
          mmsg = line.trim().substring(6);
          if(mmsg==="success") {
             if(!gosuccess) {
            setGosuccess(true);
           }
            console.log("mOTP message:"+mmsg);
          }
          else {
            setContent("Mobile OTP Error !");
          }
        }  
        if(emsg==="success" && (mmsg==="success" || !`${contnm}`.toLowerCase().includes('India'.toLowerCase()))) {
          console.log("OTP successfull !!!");
          setContent("OTPs sent ! Please check and verify above.");
          setVosuccess(true);
          if(!gosuccess) {
              setGosuccess(true);
             }
        }
        else {
          setVosuccess(false);
          setGosuccess(false);
        }
        }    
      } catch (error) {
        console.error('Error OTP gen:', error.message);
        setContent("OTPs not Generated !!!");
        setLoading(false);
      }
    }; 
        
        if(emailData.email!=='' && mobileData.mobile!=='' && !gosuccess && subsuccess ) {
          fetchData();
          setGosuccess(true);
        }
    }, [gosuccess,emailData.email,mobileData.mobile,contnm,reotp,subsuccess]);
     
      //

  //Verify OTP button
  const handleVotp = (event) => {
    event.preventDefault();
//    let isValid = true;
  
    if (isValid) {
      setVmosuccess(true);//
     console.log("mOPT entered:"+mobileData.motp+" eOTP entered:"+emailData.eotp);
      console.log("OTP Verification started ....");
    }
  };
//Verify OTPs
useEffect(() => {
//    console.log("OTP Verification started ..."+reotp+emailData.eotp);
    if (vmosuccess && !submf) {  
    console.log("Verifying OTPs. Aproaching API:");
    let mmsg='';
if(mobileData.motp!=='' && mobileData.mobile!=='' && `${contnm}`.toLowerCase().includes('India'.toLowerCase()) && (!vmotp || !veotp)) {
         console.log("Mobile OTP verification started");
         fetch(`https://servguest.eximon.com/ServConI?page=votpf&timestamp=${encodeURIComponent(new Date().getTime())}&mobile=${encodeURIComponent(mobileData.mobile)}&otp=${encodeURIComponent(mobileData.motp)}`)
  .then((response) => response.text())
  .then((responseData) => {
    const lines = responseData.split('\n');
    
    for (let line of lines) { //Data
      if (line.trim().startsWith("mmotpf")) {  
        mmsg = line.trim().substring(6);
        console.log("Verify mOTP message:");
      }
    }
    if(mmsg==="success") {
      if(!vmotp) {
       setVmotp(true);
      }
      console.log("mOTP verified!!!");
     setContent("mOTP  verified !");       
    }
  
    if(mmsg==="success"  && `${reotp}`===emailData.eotp) {
      setVeotp(true);  
      console.log("eOTP verified!!!");
      setContent("eOTP verified! Form submitted. Thanks.");
    }
    else {
      console.log("eOTP NOT verified!!!");
    }
  
  }
)
.catch((error) => {
  console.error('Error fetching data:', error.message);
  setLoading(false);
});
   }
    if((mmsg==="success" || !`${contnm}`.toLowerCase().includes('India'.toLowerCase())) && `${reotp}`===emailData.eotp) {
      setVeotp(true);  
      console.log("eOTP verified!!!");
      setContent("eOTP verified! Form submitted. Thanks.");
    }
    else {
      console.log("eOTP NOT verified!!!");
    }
if((vmotp || !`${contnm}`.toLowerCase().includes('India'.toLowerCase())) && veotp && gosuccess) {
console.log("Submitting your form !");
  setSubmf(true);
if(submf) {
  console.log("Submission can be started !!!");      
}
    }
if((!vmotp && `${contnm}`.toLowerCase().includes('India'.toLowerCase())) || !veotp){ //wrong OTPs
  console.log("OTPs not validated! Please cross check and validate again !!!");
  setContent("OTPs not validated! Please cross check and validate again !!!");
}
//    }
}
}, [vmosuccess,gosuccess,submf,mobileData.mobile,mobileData.motp,emailData.eotp,veotp,vmotp,reotp,contnm]);

//await 200624 submitting form without verification
useEffect(() => {
  const fetchData = async () => {
    console.log("Insuccess status:");
    try {
      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=react_compreg&timestamp=${encodeURIComponent(new Date().getTime())}&compregkeyid=0&mobile=${encodeURIComponent(mobileData.mobile)}&email=${encodeURIComponent(emailData.email)}&desig=${encodeURIComponent(formData.desig)}&teld=${encodeURIComponent(telData.teld)}&name=${encodeURIComponent(formData.firstname)}&lastname=${encodeURIComponent(formData.lastname)}&compname=${encodeURIComponent(formData.compname)}&city=${encodeURIComponent(formData.compcity)}&state=${encodeURIComponent(formData.compstat)}&pin=${encodeURIComponent(numData.comppin)}&cont=${encodeURIComponent(contnm)}&cbuscat=${encodeURIComponent(selectedCompcat)}&identity=${encodeURIComponent(selectedSoftradio)}&mcomptype=${encodeURIComponent(selectedComptype)}&website=${encodeURIComponent(formData.website)}`);
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      const responseData = await response.text();
      // Process the responseData as needed
      console.log('Data received:', responseData);
      const lines = responseData.split('\n');
    let msg='';
    for (let line of lines) { //Data
      if (line.trim().startsWith("mcontchkf")) { 
        msg = line.trim().substring(9);
        console.log("Form submission status:"+msg);
      }
      if (line.trim().startsWith("mcompregkeyid")) { 
        let ck = line.trim().substring(13);
        console.log("Comp key:");
        setCompkey(ck);
      }
      }      
    if(msg==="success") {
      setSubsuccess(false);
      setContent("Form submitted, verify OTPs !!!");
      console.log("Form submitted, verify OTPs !!!");
      console.log("Aproaching gtag conversion !!!"); 
      gtag_report_conversion();

    }
    else {
      setContent("Form not submitted !!!");      
      console.log("Form not submitted !!!");
    }
  } catch (error) {
    console.error('Error Form submission:', error.message);
    setContent("Form not submitted !!!");
    setLoading(false);
  }
};
if (subsuccess && success && gosuccess && !insucess) { //submit success true && form validation success && no duplicates success && Gen OTP false
      initGoogleAnalytics();//311223 for google ads conversion tracking
      console.log("Aproaching Submit API!!!");
      if(mobileData.mobile!=='' && formData.compname!=='') {
        console.log("Aproaching API for new record insert");  
fetchData();
         setInsucess(true);
       }
    }
}, [insucess,contnm,compkey,subsuccess,success,gosuccess,emailData.email,formData.compcity,formData.compname,formData.compstat,formData.cont,formData.desig,formData.firstname,formData.lastname,mobileData.mobile,numData.comppin,selectedCompcat,selectedComptype,selectedSoftradio,telData.teld,formData.website,formData.softradio,gtag_report_conversion]);
//

//await 190624 update verify flag after otp verification
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`https://servguest.eximon.com/AndyCon?app=react_compreg_upd&timestamp=${encodeURIComponent(new Date().getTime())}&compregkeyid=${encodeURIComponent(compkey)}`);
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      const responseData = await response.text();
      // Process the responseData as needed
      console.log('Data received:', responseData);
      const lines = responseData.split('\n');
    let msg='';
    for (let line of lines) { //Data
      if (line.trim().startsWith("mcontchkf")) { 
        msg = line.trim().substring(9);
        console.log("Form update status:"+msg);
      }
      }      
    if(msg==="success") {
      setContent("Form updated successfully !!!");
      console.log("Form updated successfully!!!");
      // console.log("Aproaching gtag conversion!!!"); 
      // gtag_report_conversion();
     
      setFormData({
      compname: '',
      compcity: '',
      compstat: '',
      firstname: '',
      lastname: '',
      desig: '',
      softradio: '',
      cont:{contnm},
      website: ''});
      setMobileData({
      mobile: '',
      motp: ''
      });
      setEmailData({
       email: '',
       eotp: ''
      });
      setTelData({
        teld: ''
      });
      setNumData({
        comppin: ''
      });
    }
    else {
      setContent("Form not updated !!!");      
    }
      setGosuccess(false);      
      setSuccess(false);
    } catch (error) {
      console.error('Error Form submission:', error.message);
      setLoading(false);
    }
  };

  // Call the fetchData function
    // Update record after validate form data successfull
  
   if (veotp && (vmotp || !`${contnm}`.toLowerCase().includes('India'.toLowerCase())) && gosuccess && !subsuccess ) {
     console.log("Aproaching Submit API!!!");
     if(mobileData.mobile!=='' && formData.compname!=='' && compkey>0) {
       console.log("Updating record for key ");
  fetchData();
     }
}
}, [subsuccess,compkey,vmotp,veotp,gosuccess,emailData.email,formData.compcity,formData.compname,formData.compstat,formData.cont,formData.desig,formData.firstname,formData.lastname,mobileData.mobile,numData.comppin,selectedCompcat,selectedComptype,selectedSoftradio,telData.teld,formData.website,formData.softradio,contnm,gtag_report_conversion]); // Empty dependency array means this effect runs once after initial render

//


return (
    <div>
      <div id="contact">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>Get In Touch</title> */}
      <link rel="canonical" href="https://eximon.com/#contact" />
      <meta name="description" content="Please fill out the form below to generate online quotation, Software Demo and we will get back to you as soon as possible." />
      <meta name="keywords" content="Contact Information, Email, Phone Number, Office Locations, Customer Support, Business Inquiry, Get in Touch" />
      <meta property="og:title" content="Get In Touch" />
    </Helmet>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h1>Get In Touch</h1>
                <h2>
                  Please fill out the form below to generate online quotation, Software Demo and we will
                  get back to you as soon as possible.
                </h2>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                   <div className="radio-inline-container"> 
                    <p><b>Select Software:<span style={{color:'red'}}>  *</span></b></p>
                    <div className="radio-inline">
                    <label htmlFor="emsschk" style={{fontWeight: 'bold',color: 'white'  }}>
                      <input
                        type="radio"
                        id="emsschk"
                        name="softradio"
                        value="emssenq"
                        onChange={handleEmsschk}
                      />                     
                      {selectedEmsschk==='emssenq'?'EMSS Software':'EMSS Software'}
                      </label>
                      </div>
                    <div className="radio-inline">
                    <label htmlFor="icligmchk" style={{fontWeight: 'bold',color: 'white'  }}>                      
                      <input
                        type="radio"
                        id="icligmchk"
                        name="softradio"
                        value="icligmenq"
                        onChange={handleIcligmchk}
                      />                      
                      {selectedIcligmchk==='icligmenq'?'ICLIGM Software':'ICLIGM Software'}
                      </label>
                      </div>

                      <div className="radio-inline">
                    <label htmlFor="chatbotchk" style={{fontWeight: 'bold',color: 'white'  }}>
                      <input
                        type="radio"
                        id="chatbotchk"
                        name="softradio"
                        value="chatbot"
                        onChange={handleChatbotchk}
                      />
                      {selectedChatbotchk==='chatbot'?'ChatBot (GenAI)':'ChatBot (GenAI)'}                      
                      </label>
                      </div>
                      <div className="radio-inline">
                    <label htmlFor="otherchk" style={{fontWeight: 'bold',color: 'white'  }}>
                      <input
                        type="radio"
                        id="otherchk"
                        name="softradio"
                        value="others"
                        onChange={handleOtherchk}
                      />
                      {selectedOtherchk==='others'?'Others':'Others'}                      
                      </label>
                      </div>
                      </div>
                      <p className="help-block text-danger"></p>
                    </div>
                   </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="compname"
                        name="compname"
                        className="form-control"
                        placeholder="Company Name"
                        required
                        onChange={handleInputChange}
                        maxLength={75}
                        minLength={5}
                        value={formData.compname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="website"
                        name="website"
                        className="form-control"
                        placeholder="Company website"
                        onChange={handleWebsiteChange}
                        maxLength={100}
                        value={formData.website}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <select value={selectedComptype} onChange={handleComptypeChange} id="comptype" name="comptype" type="select" className="form-control" title="Company type" required>
        <option value="1">
          Select Company type
        </option>
        <option value="2">Limited</option>
        <option value="3">Pvt Ltd</option>
        <option value="4">Proprietor</option>
        <option value="5">Partnership</option>
        <option value="6">LLC</option>
        <option value="7">Government</option>
      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                    <select value={selectedCompcat} onChange={handleCompcatChange} id="compcat" name="compcat" type="select" className="form-control" title="Business category" required>
                    {compcatOptions.map((option) => (
          <option key={option} value={option}>
            {getOptionLabel(option)}
          </option>
        ))}
      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="compcity"
                        name="compcity"
                        className="form-control"
                        placeholder="City"
                        required
                        onChange={handleInputChange}
                        maxLength={45}
                        value={formData.compcity}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="compstat"
                        name="compstat"
                        className="form-control"
                        placeholder="State"
                        required
                        onChange={handleInputChange}
                        maxLength={45}
                        value={formData.compstat}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>       
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="compcont"
                        name="compcont"
                        className="form-control"
                        placeholder="Country"
                        required
                        onChange={(e)=>setContnm(e.target.value)}
                        maxLength={45}
                        defaultValue={contnm}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="comppin"
                        name="comppin"
                        className="form-control"
                        placeholder="Postal code (Pin code)"
                        required
                        onChange={handlePinInput}
                        value={numData.comppin}
                        maxLength={7}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>                
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        className="form-control"
                        placeholder="Your first and middle name"
                        required
                        onChange={handleInputChange}
                        maxLength={75}
                        value={formData.firstname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        className="form-control"
                        placeholder="Last name"
                        required
                        onChange={handleInputChange}
                        maxLength={35}
                        value={formData.lastname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="teld"
                        name="teld"
                        className="form-control"
                        placeholder="Telephone no. (office)"
                        required
                        onChange={handleTelInput}
                        value={telData.teld}
                        maxLength={75}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="desig"
                        name="desig"
                        className="form-control"
                        placeholder="Your designation"
                        required
                        onChange={handleInputChange}
                        maxLength={75}
                        value={formData.desig}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>   
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        className="form-control"
                        placeholder="Mobile number"
                        required
                        onChange={handleMobileInput}
                        value={mobileData.mobile}
                        maxLength={10}
                        minLength={10}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email ID (Official)"
                        required
                        onChange={handleEmailInput}
                        maxLength={100}
                        value={emailData.email}
                      />
                      <p className={`help-block text-${emailValue ? 'success' : 'danger'}`}>
                          {emailValue ? 'Email is Okay !!!' : 'Please provide correct Email ID'}</p>
                    </div>
                  </div>                  
                </div>    
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="mopt"
                        name="motp"
                        className="form-control"
                        placeholder="Enter Mobile OTP here"
                        maxLength={4}
                        minLength={4}
                        pattern="^[0-9]{4}$"
                        style={{ display: (gosuccess && !vduplicate && `${contnm.toLowerCase()}`.includes('India'.toLowerCase())) ? 'block' : 'none' }}
                        onInput={(event) => {
                          // Replace non-digit characters with an empty string
                          event.target.value = event.target.value.replace(/[^0-9]/g, '');
                     // Update the state
                     setMobileData(prevState => ({
                      ...prevState,
                      motp: event.target.value
                    }));
                        }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="eotp"
                        name="eotp"
                        className="form-control"
                        placeholder="Enter Email OTP here"
                        maxLength={4}
                        minLength={4}
                        pattern="^[0-9]{4}$"
                        style={{ display: (gosuccess && !vduplicate) ? 'block' : 'none' }}
                        onInput={(event) => {
                          // Replace non-digit characters with an empty string
                          event.target.value = event.target.value.replace(/[^0-9]/g, '');
                         // Update the state
                              setEmailData(prevState => ({
                                ...prevState,
                                eotp: event.target.value
                              }));
                        }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>                  
                </div>                                             
                <div className="btn-group">                
                <div id="success" className="bold-text">{content}</div>
                <button disabled={!softSelect} type="submit" className="btn btn-custom btn-lg">
                  Verify details
                </button>
                <button id="gotp" onClick={handleGotp} type="button" className="btn btn-custom btn-lg" disabled={!success}>
                  Generate OTPs
                </button>                
                <button id="votp" onClick={handleVotp} type="button" className="btn btn-custom btn-lg" disabled={!vosuccess}>
                  Verify OTPs
                </button>                                
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-wordpress"></i>
                  {props.data ? <a href={props.data.wordpress} style={{color:'white'}} target="_blank" rel="noopener noreferrer">Eximon @ WP</a> : "loading"}
                </span>{" "}
              </p>
              <p>
                <span>
                  <i className="fa fa-google-plus"></i>
                  {props.data ? <a href={props.data.google} style={{color:'white'}} target="_blank" rel="noopener noreferrer">Immortal @ Google</a> : "loading"}
                </span>{" "}
              </p>
              <p>
                <span>
                  <i className="fa fa-google-plus-square"></i>
                  {props.data ? <a href={props.data.google2} style={{color:'white'}} target="_blank" rel="noopener noreferrer">ICL @ Google</a> : "loading"}
                </span>{" "}
              </p>                            
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Immortal Computer Lab Pvt. Ltd.
          </p>
          <p><a href="#privpol">Privacy policy</a>

          </p>
        </div>
      </div>
    </div>
  );
};
